export function checkState(state) {
  return state === undefined || state === null || state === "";
}

export function checkStateBaseAPI(state, baseAPI) {
  return (
    state === undefined ||
    state === null ||
    state === "" ||
    baseAPI === null ||
    baseAPI === "" ||
    baseAPI === undefined
  );
}
