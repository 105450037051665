export const headline = {
  forgotPassword: "No problem, let's reset it.",
  resetPassword: "You're almost done!",
  resetPasswordSuccess: "Your password has been changed.",
  forgotUsername: "No problem, let's look it up.",
  forgotUsername2: "No problem, let's look it up.",
  validateActivation: "Let’s set up your online account.",
  resendActivationMail: "Let’s set up your online account.",
  unlockAccountSuccess: "You may now sign into your account.",
  needHelp: "Need Help?",
  
};
