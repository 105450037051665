import axios from "axios";
import { api } from "../utils/api";
import { href } from "../utils/ui-urls";

export const axiosInstance = axios.create();
export const key = "accessToken";
export const creditOne500 = href.error500;

export const removeAccessTokenUrls = [
  api.forgotPassword,
  api.resetMfa,
  api.validateActivation,
  api.forgotUsername,
];

axiosInstance.interceptors.request.use(
  function (config) {
    if (removeAccessTokenUrls.some((url) => config.url.endsWith(url))) {
      localStorage.removeItem(key);
    }

    const token = localStorage.getItem(key);
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    if (error.response.status === 403) {
      localStorage.removeItem(key);
      window.location.href = href.sessionExpired;
    } else return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    const token = response.headers.accesstoken;
    if (token) {
      localStorage.setItem(key, token);
    }
    return response;
  },
  function (error) {
    if (error.response.status === 403) {
      localStorage.removeItem(key);
      window.location.href = href.sessionExpired;
    } else return Promise.reject(error);
  }
);
