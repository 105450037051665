export const linkText = {
  forgotUsername: "Forgot username?",
  noEmailAccess: "Don't have access to email?",
  accessEmail: "Use email address instead",
  resendActivationMail: "Resend Activation Email",
  signIn: "Sign In",
  backToSignIn: "Back To Sign In",
  needHelp: "Need help?",
  customerServie: "Customer Service",
  resetPassword: "Reset Password",
  callAgain: "Call again",
  sendAgain: "Send again",
  answerSecurityQuestion: "Try verifying another way",
  backToFactors: "Choose a different way to verify",
};
