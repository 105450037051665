import React, { useState, useEffect } from "react";
import { label } from "../../text/labels.text";
import { title } from "../../text/page-title.text";
import { api } from "../../utils/api";
import { msg } from "../../text/form-message.text";
import { button } from "../../text/button.text";
import { href } from "../../utils/ui-urls";
import { useNavigate, useLocation } from "react-router-dom";
import { formTitle } from "../../text/form-title.text";
import PasswordChecklist from "../../components/password-checklist/password-checklist.component";
import { InputPassword, Button, Title } from "@creditonebank/c1b-components";
import { passwordValidate } from "../../text/password-validation.text";
import { validatePassword } from "../../functions/validate-password.function";
import Alert from "../../components/alert/alert.component";
import { LoaderComponent } from "../../components/loader/loader.component";
import { axiosInstance } from "../../service/https.service";
import { errorMsg } from "../../text/error-message.text";
import { checkServerError } from "../../functions/check-server-error.function";
import { checkState } from "../../functions/check-state.function";
import { HelpLink } from "../../components/link/help-link.component";

const ResetPassword = ({ baseAPI, helpLink }) => {
  const { state } = useLocation();

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [error, setError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [os, setOs] = useState("")
  const [browser, setBrowser] = useState("")

  useEffect(() => {
    validatePassword(newPassword, setValidate);
  }, [newPassword]);

  useEffect(() => {
    document.title = title.resetPassword;
    window.scrollTo(0, 0);
    if (checkState(state)) {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;

    const mobile = Boolean(
      userAgent.match(
        /Mobile|Andriod|WebOS|iPod|iPhone|iPad|BlackBerry|Windows Phone/i
      )
    );
    
    // find OS
    if (userAgent.indexOf("Win") !== -1) {
      setOs("Windows");
    } else if (userAgent.indexOf("Mac") !== -1) {
      if (mobile) setOs("iOS");
      else setOs("MacOs");
    } else if (userAgent.indexOf("Android") !== -1) {
      setOs("Android");
    } else if (userAgent.indexOf("Linux") !== -1) {
      setOs("Linux");
    } else {
      setOs("Unknown");
    } 

    // find browser
    let chromeAgent =
      userAgent.indexOf("Chrome") !== -1 || userAgent.indexOf("CriOS") !== -1;
    let mozillaAgent = userAgent.indexOf("Mozilla") !== -1;
    let safariAgent = userAgent.indexOf("Safari") !== -1;
    let microsoftEdgeEvent = userAgent.indexOf("Edg") !== -1;
    let operaEvent = userAgent.indexOf("OP") !== -1;
    let firefoxAgent = userAgent.indexOf("Firefox") !== -1;

    if (chromeAgent && mozillaAgent && safariAgent && microsoftEdgeEvent) {
      setBrowser("Microsoft Edge");
    } else if (chromeAgent && operaEvent && mozillaAgent && safariAgent) {
      setBrowser("Opera");
    } else if (chromeAgent && mozillaAgent && safariAgent) {
      setBrowser("Chrome");
    } else if (mozillaAgent && safariAgent) {
      setBrowser("Safari");
    } else if (mozillaAgent && firefoxAgent) {
      setBrowser("Firefox");
    } else if (mozillaAgent) {
      setBrowser("Internet Explorer");
    } else {
      setBrowser("Unknown");
    }
  }, []);

  const [validate, setValidate] = useState({
    hasLow: false,
    hasCap: false,
    hasNumber: false,
    hasLength8: false,
    hasSpecialCharacter: false
  });

  const strength = Object.values(validate).reduce((a, item) => a + item, 0);

  const navigate = useNavigate();

  function handleChange() {
    setError(false);
    setNewPasswordError(false);
    setConfirmNewPasswordError(false);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setErrorTitle("");
    setErrorDescription("");
    setError(false);
    setNewPasswordError(false);
    setConfirmNewPasswordError(false);

    if (newPassword === "" && confirmNewPassword === "") {
      setErrorTitle(passwordValidate.blankPasswordsValidateUser2.errorTitle);
      setErrorDescription(
        passwordValidate.blankPasswordsValidateUser2.errorDesc
      );
      setError(true);
      window.scrollTo(0, 0);
    } else if (!passwordValidate.regex.test(newPassword)) {
      setErrorTitle(passwordValidate.passwordInvalid.errorTitle);
      setErrorDescription(passwordValidate.passwordInvalid.errorDesc);
      setNewPasswordError(true);
      window.scrollTo(0, 0);
    } else if (confirmNewPassword === "") {
      setErrorTitle(passwordValidate.blankConfirmPassword.errorTitle);
      setErrorDescription(passwordValidate.blankConfirmPassword.errorDesc);
      setConfirmNewPasswordError(true);
      window.scrollTo(0, 0);
    } else {
      if (newPassword.match(passwordValidate.regex)) {
        if (newPassword === confirmNewPassword) {
          setShowLoader(true);
          axiosInstance
            .put(baseAPI + state.id + api.resetPassword, {
              newPassword: newPassword,
            },{
              headers : {
                device : browser + " - " + os
              }
            })
            .then(() => {
              setShowLoader(false);
              navigate(href.resetPasswordSuccess, {
                replace: true,
              });
            })
            .catch((error) => {
              setShowLoader(false);
              if (checkServerError(error.response.status)) {
                setErrorTitle(errorMsg.internalServer.errorTitle);
                setErrorDescription(errorMsg.internalServer.errorDesc);
                setError(true);
              } else {
                setErrorTitle(error.response.data.error.title);
                setErrorDescription(error.response.data.error.message);
                setError(true);
              }
              window.scrollTo(0, 0);
            });
        } else {
          setErrorTitle(passwordValidate.passwordsNoMatch.errorTitle);
          setErrorDescription(passwordValidate.passwordsNoMatch.errorDesc);
          setNewPasswordError(true);
          setConfirmNewPasswordError(true);
          window.scrollTo(0, 0);
        }
      } else {
        setErrorTitle(passwordValidate.passwordInvalid.errorTitle);
        setErrorDescription(passwordValidate.passwordInvalid.errorDesc);
        setNewPasswordError(true);
        window.scrollTo(0, 0);
      }
    }
  }

  return (
    <div className="bg">
      {showLoader ? (
        <LoaderComponent />
      ) : (
        <div className="form-element">
          <form onSubmit={(e) => handleSubmit(e)} className="fp-form">
            <div className="title">
              <Title tag="h2" className="web-title">
                {formTitle.resetPassword}
              </Title>

              <Title tag="h2" className="mobile-title" align="center">
                {formTitle.resetPassword}
              </Title>
            </div>

            <div className="feedback-alert">
              <Alert
                alertType="error"
                title={errorTitle}
                description={errorDescription}
                showAlert={error || newPasswordError || confirmNewPasswordError}
              />
            </div>

            <div className="description">
              <p className="desc2">{msg.resetPassword}</p>
            </div>

            <InputPassword
              id="pw2"
              value={newPassword}
              label={label.newPassword}
              labelClass={error || newPasswordError ? "label-error" : null}
              name="pw2-input"
              type="password"
              regex={passwordValidate.regex}
              error={error || newPasswordError}
              noErrorsOrHelper
              hideAlertIcon
              showHide={{
                showText: "show",
                hideText: "hide",
              }}
              setValue={setNewPassword}
              onChange={handleChange}
              autoFocus
              minLength={8}
              maxLength={20}
              description="A strong password has a mix of letters, numbers, and special characters."
              data-testid="test-pw2"
            />
            <PasswordChecklist password={newPassword} />

            <InputPassword
              id="pw"
              value={confirmNewPassword}
              label={label.confirmNewPassword}
              labelClass={
                error || confirmNewPasswordError ? "label-error" : null
              }
              name="password-input"
              type="password"
              customValidation={[]}
              error={error || confirmNewPasswordError}
              noErrorsOrHelper
              hideAlertIcon
              showHide={{
                showText: "show",
                hideText: "hide",
              }}
              setValue={setConfirmNewPassword}
              onChange={handleChange}
              minLength={8}
              maxLength={20}
              data-testid="test-pw"
            />

            {strength === 5 && newPassword === confirmNewPassword ? (
              <Button
                type="submit"
                onClick={handleSubmit}
                size="medium"
                className="button-style-2"
                data-testid="test-btnResetPassword"
              >
                {button.resetPassword}
              </Button>
            ) : (
              <Button
                size="medium"
                className="button-style-2"
                variant="inactive"
                data-testid="test-btnResetPassword"
              >
                {button.resetPassword}
              </Button>
            )}
          </form>
        </div>
      )}

      <div className="centre-align out-of-box">
        <HelpLink helpLink={helpLink} />
      </div>
    </div>
  );
};

export default ResetPassword;
