import { useEffect } from "react";
import { LoaderComponent } from "../../components/loader/loader.component";

const AccountLocked = () => {
  useEffect(() => {
    return fetch("/env")
      .then((response) => response.json())
      .then((data) => {
        const accessCreditOneUrl = data.REACT_APP_ACCESS_CREDITONE_URL;
        window.location.replace(`${accessCreditOneUrl}?UserAccountLocked=true`);
      });
  }, []);

  return (
    <div className="bg">
      <LoaderComponent />
    </div>
  );
};

export default AccountLocked;
