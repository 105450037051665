import React, { useEffect } from "react";
import { formTitle } from "../../text/form-title.text";
import { button } from "../../text/button.text";
import { Button, Title } from "@creditonebank/c1b-components";
import { title } from "../../text/page-title.text";
import { msg } from "../../text/form-message.text";

const AccountUnlockedError = ({ signInUrl }) => {
  useEffect(() => {
    document.title = title.accountUnlockedError;
    window.scrollTo(0, 0);
  }, []);

  const handleSignIn = () => {
    window.location.href = signInUrl;
  };

  return (
    <div className="bg">
      <div className="form-element-username-success">
        <div className="title">
          <Title tag="h2">
            {formTitle.accountUnlockedError}
          </Title>
        </div>

        <div className="description">
          <p className="desc2">{msg.accountUnlockedError}</p>
        </div>

        <Button
          onClick={handleSignIn}
          className="button-style-success"
          size="medium"
        >
          {button.signIn}
        </Button>
      </div>
    </div>
  );
};

export default AccountUnlockedError;
