import { Button } from "@creditonebank/c1b-components";
import { linkText } from "../../text/links.text";

export const HelpLink = ({ helpLink }) => {
  const handleHelp = () => {
    window.location.href = helpLink;
  };

  return (
    <>
      {helpLink == null ? null : (
        <Button
          variant="link"
          size="small"
          onClick={handleHelp}
          style={{ padding: "0px", margin: "0px" }}
        >
          {linkText.needHelp}
        </Button>
      )}
    </>
  );
};
