import React, { useEffect } from "react";
import { msg } from "../../text/form-message.text";
import { href } from "../../utils/ui-urls";
import { title } from "../../text/page-title.text";
import { formTitle } from "../../text/form-title.text";
import { headline } from "../../text/form-headline.text.js";
import { button } from "../../text/button.text";
import { Button, Title } from "@creditonebank/c1b-components";
import { linkText } from "../../text/links.text";
import { successMsg } from "../../text/success.text";
import Alert from "../../components/alert/alert.component";
import { HelpLink } from "../../components/link/help-link.component";

const UnlockAccountSuccess = ({ signInUrl, helpLink }) => {
  useEffect(() => {
    document.title = title.unlockAccountSuccess;
    window.scrollTo(0, 0);
  }, []);

  const handleSignIn = () => {
    window.location.href = signInUrl;
  };

  const handleResetPassword = () => {
    window.location.href = href.forgotPassword;
  };

  return (
    <div className="bg">
      <div className="form-element-unlock-success">
        <div className="title">
          <Title tag="h2" className="web-title">
            {formTitle.unlockAccountSuccess}
          </Title>
          <Title tag="h2" className="mobile-title" align="center">
            {formTitle.unlockAccountSuccess}
          </Title>
        </div>

        <div className="feedback-success">
          <Alert
            alertType="success"
            title={successMsg.unlockAccountSuccess}
            showAlert={true}
          />
        </div>

        <p className="headline-style">{headline.unlockAccountSuccess}</p>
        <p className="desc2">{msg.unlockAccountSuccess}</p>

        <Button
          onClick={handleSignIn}
          size="medium"
          className="button-style"
          style={{
            width: "100%",
            marginTop: "24px",
            marginBottom: "28px",
            padding: "12px 32px",
          }}
        >
          {button.unlockAccountSuccess}
        </Button>
        <div className="centre-align inside-box">
          <Button
            variant="link"
            size="small"
            onClick={handleResetPassword}
            style={{ marginBottom: "4px", padding: "0" }}
          >
            {linkText.resetPassword}
          </Button>
        </div>
      </div>
      <div className="centre-align need-help-unlock-account-success">
        <HelpLink helpLink={helpLink} />
      </div>
    </div>
  );
};

export default UnlockAccountSuccess;
