import { Button } from "@creditonebank/c1b-components";
import { linkText } from "../../text/links.text";

export const BackToSignIn = ({ signInUrl }) => {
  const handleSignIn = () => {
    window.location.href = signInUrl;
  };

  return (
    <>
      {signInUrl == null ? null : (
        <Button
          variant="link"
          icon="back"
          size="small"
          style={{ padding: "0px", margin: "0px" }}
          onClick={handleSignIn}
        >
          {linkText.backToSignIn}
        </Button>
      )}
    </>
  );
};
