export const title = {
  forgotPassword: "Forgot Password | Credit One Bank",
  mfaChallenge: "Verify Your Identity | Credit One Bank",
  mfaVerify: "Verify Your Identity | Credit One Bank",
  forgotPasswordSecurity: "Reset Password | Credit One Bank",
  resetPassword: "Reset Password | Credit One Bank",
  resetPasswordSuccess: "Password Reset | Credit One Bank",

  resendActivationMail: "Resend Activation Email | Credit One Bank",
  updateEmail: "Online Account Setup | Credit One Bank",
  resendActivationSuccess: "Resend Activation Email | Credit One Bank",

  forgotUsername: "Forgot Username | Credit One Bank",
  forgotUsername2: "Forgot Username | Credit One Bank",
  securityQuestion: "Forgot Username | Credit One Bank",
  forgotUsernameSuccess: "Forgot Username | Credit One Bank",

  validateActivation: "Welcome to Credit One Bank | Credit One Bank",
  activateUser: "Welcome to Credit One Bank | Credit One Bank",
  tokenExpired: "Welcome to Credit One Bank | Credit One Bank",
  enrollSecurityQuestions: "Welcome to Credit One Bank | Credit One Bank",
  accountActivationSuccess: "Your Account is Ready | Credit One Bank",

  resetMfaInfo: "Reset Multi-factor Authentication | Credit One Bank",
  resetMfa: "Reset Multi-factor Authentication | Credit One Bank",
  securityQuestionMfa: "Reset Multi-factor Authentication | Credit One Bank",
  confirmResetMfa: "Reset Multi-factor Authentication | Credit One Bank",
  resetMfaSuccess: "Reset Multi-factor Authentication | Credit One Bank",

  unlockAccount: "Unlock Account | Credit One Bank",
  unlockAccountSuccess: "Account Unlocked | Credit One Bank",

  sessionExpired: "Session Expired | Credit One Bank",
  requestError: "Request Error | Credit One Bank",
  accountNotActive: "Forgot Password | Credit One Bank",
  accountUnlockedError: "Account Unlocked | Credit One Bank",
  accountActive: "Account is Already Active | Credit One Bank",
  resetMfaError: "Request Error | Credit One Bank",
};
