import "./alert.component.css";
import { ReactComponent as ErrorAlertIcon } from "../../assets/ErrorAlertIcon.svg";
import { ReactComponent as SuccessAlertIcon } from "../../assets/SuccessAlertIcon.svg";
import PropTypes from "prop-types";

const Alert = ({ alertType, title, description, showAlert }) => {
  return (
    <>
      {showAlert && (
        <div className="alert-message-box">
          {alertType === "error" && (
            <div className="alert-content-error alert-content">
              <figure className="alert-icon">
                <ErrorAlertIcon className="Icon-utility" />
              </figure>
              <section>
                <p className="alert-error-title">{title}</p>
                <span className="alert-error-description">{description}</span>
              </section>
            </div>
          )}

          {alertType === "success" && (
            <div className="alert-content-success alert-content">
              <figure className="alert-icon">
                <SuccessAlertIcon className="Icon-utility" />
              </figure>
              <section>
                <p className="alert-success-title">{title}</p>
                <span className="alert-success-description">{description}</span>
              </section>
            </div>
          )}
        </div>
      )}
    </>
  );
};

Alert.propTypes = {
  alertType: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  showAlert: PropTypes.bool,
};

Alert.defaultProps = {
  alertType: "",
  title: "",
  description: "",
  showAlert: false,
};

export default Alert;
