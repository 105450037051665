export const label = {
  username: "Username",
  ssn: "Social Security Number",
  governmentIdentifier: "Social Security Number",
  globalPin: "Global Pin",
  email: "Email Address",
  phone: "Phone Number",
  newPassword: "New Password",
  confirmNewPassword: "Re-enter New Password",
  newUsername: "Choose Your Username",
  password: "Password",
  reEnterPassword: "Re-enter Password",

  securityQuestion1: "Security Question 1",
  securityQuestion2: "Security Question 2",
  securityQuestion3: "Security Question 3",

  oldPassword: "Old Password",

  mobileNumber: "Mobile Number",
  verificationCode: "Verification Code",
  smsAsk: "Didn't receive the code?",
  resend: "Resend",
  or: "OR",

  mfaVerify: "Verification Code",
  needCode: "Need a new code?",
};
