import { useEffect } from "react";
import "./Home.css";
import { LoaderComponent } from "./components/loader/loader.component";

const Home = () => {
  useEffect(() => {
    return fetch("/env")
      .then((response) => response.json())
      .then((data) => {
        const signInUrl = data.REACT_APP_SIGN_IN_URL;
        window.location.href = signInUrl;
      });
  }, []);

  return (
    <div className="bg">
      <LoaderComponent />
    </div>
  );
};

export default Home;
