export const button = {
  forgotPassword: "Continue",
  accountNotActive: "Resend Activation Email",
  forgotPasswordSecurity: "Continue",
  resetPassword: "Reset Password",

  validateActivation: "Continue",
  resendActivation: "Resend Activation Email",
  activateUser: "Continue",
  enrollSecurityQuestions: "Continue",

  forgotUsername: "Find Username",
  forgotUsernameMobile: "Continue",
  forgotUsername2: "Continue",
  securityQuestion: "Get Username",

  resetMfaInfo: "Reset Two-Factor Authentication",
  resetMfa: "Continue",
  securityQuestionMfa: "Continue",

  resendActivationMail: "Continue",
  updateEmail: "Submit",

  unlockAccount: "Continue",
  unlockAccountSuccess: "Continue to Sign In",

  confirmResetMfa: "Yes, Confirm Reset",
  resetMfaSuccess: "Go to Sign In",

  signIn: "Return to Sign In",
  activationSignIn: "Go to Sign In",
  ok: "OK",

  verifyCode: "Verify Code",
};
