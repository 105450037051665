import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { extraMsg, msg } from "../../text/form-message.text";
import { button } from "../../text/button.text";
import { linkText } from "../../text/links.text";
import { formTitle } from "../../text/form-title.text";
import {
  InputNumber,
  Button,
  Title,
  Label,
  ProgressIndicator,
} from "@creditonebank/c1b-components";
import { label } from "../../text/labels.text";
import { LoaderComponent } from "../../components/loader/loader.component";
import "../../style/mfa-challenge.style.css";
import Alert from "../../components/alert/alert.component";
import { href } from "../../utils/ui-urls";
import { title } from "../../text/page-title.text";
import { axiosInstance } from "../../service/https.service";
import { errorMsg } from "../../text/error-message.text";
import { successMsg } from "../../text/success.text";
import { errorResponse } from "../../text/error.responses.text";
import { checkServerError } from "../../functions/check-server-error.function";
import { checkState } from "../../functions/check-state.function";
import { HelpLink } from "../../components/link/help-link.component";

const MfaVerify = ({
  baseAPI,
  stepNumber,
  stepNames,
  mobileStepNames,
  helpLink,
}) => {
  useEffect(() => {
    document.title = title.mfaVerify;
    window.scrollTo(0, 0);
    if (checkState(state)) {
      window.location.href = "/";
    }
  }, []);

  const { state } = useLocation();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [code, setCode] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [error, setError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleChange = () => {
    setError(false);
  };

  const [buttonDisabled, setButtonDiabled] = useState(true);

  const handleGoBack = () => {
    navigate(href.mfaChallenge, {
      state: {
        id: state.id,
        phone: state.phone,
        email: state.email,
        primaryPhone: state.primaryPhone,
        factors: state.factors,
        securityQuestionsAvailable: state.securityQuestionsAvailable,
        navigateHref: state.navigateHref,
      },
      replace: true,
    });
  };

  const handleSecurityQuestion = () => {
    if (state.securityQuestionsAvailable)
      navigate(href.forgotPasswordSecurity, {
        state: {
          id: state.id,
        },
        replace: true,
      });
    else navigate(href.requestError);
  };

  const handleResendCode = () => {
    setShowLoader(true);
    axiosInstance
      .post(
        baseAPI + state.id + "/factor/" + state.factorId + "/challenge",
        null
      )
      .then(() => {
        setShowLoader(false);
        setButtonDiabled(true);
        setShowSuccess(true);
        setError(false);
        setTimeout(() => {
          setButtonDiabled(false);
          setShowSuccess(false);
        }, 30000);
      })
      .catch((error) => {
        if (checkServerError(error.response.status)) {
          setShowLoader(false);
          setErrorTitle(errorMsg.internalServer.errorTitle);
          setErrorDescription(errorMsg.internalServer.errorDesc);
          setError(true);
          window.scrollTo(0, 0);
        }
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setButtonDiabled(false);
    }, 30000);
    return () => clearTimeout(timer);
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    setErrorTitle("");
    setErrorDescription("");
    setError(false);
    setShowSuccess(false);

    if (code === "") {
      setErrorTitle(errorMsg.missingOtpCode.errorTitle);
      setErrorDescription(errorMsg.missingOtpCode.errorDesc);
      setError(true);
      setShowSuccess(false);
      window.scrollTo(0, 0);
    } else {
      setShowLoader(true);
      axiosInstance
        .post(baseAPI + state.id + "/factor/" + state.factorId + "/verify", {
          passCode: code,
        })
        .then((response) => {
          setShowLoader(false);
          navigate(state.navigateHref, {
            state: {
              id: response.data.id,
            },
            replace: true,
          });
        })
        .catch((error) => {
          if (checkServerError(error.response.status)) {
            setShowLoader(false);
            setErrorTitle(errorMsg.internalServer.errorTitle);
            setErrorDescription(errorMsg.internalServer.errorDesc);
            setError(true);
            window.scrollTo(0, 0);
          } else {
            if (
              error.response.data.error.title === errorResponse.accountLocked
            ) {
              navigate(href.accountLocked);
            } else {
              setShowLoader(false);
              setErrorTitle(error.response.data.error.title);
              setErrorDescription(error.response.data.error.message);
              setError(true);
              window.scrollTo(0, 0);
            }
          }
        });
    }
  }

  return (
    <div className="bg">
      {state != null ? (
        state.navigateHref === href.updateEmail ? (
          <div className="progress-stepper-style validate-progress">
            <ProgressIndicator
              className="web-progress-stepper"
              currentIndex={stepNumber}
              stepNames={stepNames}
            />
            <ProgressIndicator
              className="mobile-progress-stepper"
              currentIndex={stepNumber}
              stepNames={mobileStepNames}
            />
          </div>
        ) : null
      ) : null}
      {showLoader ? (
        <LoaderComponent />
      ) : (
        <div className="form-element mfa-container">
          <div className="title">
            <Title tag="h2" className="web-title">
              {formTitle.mfaChallenge}
            </Title>

            <Title tag="h2" className="mobile-title" align="center">
              {formTitle.mfaChallenge}
            </Title>
          </div>

          <div className="feedback-alert">
            <Alert
              alertType="error"
              title={errorTitle}
              description={errorDescription}
              showAlert={error}
            />
          </div>

          <div className="feedback-success">
            <Alert
              alertType="success"
              title={successMsg.codeResent}
              showAlert={showSuccess}
            />
          </div>

          <div className="description">
            <p className="desc2">
              {state != null
                ? state.type === "call"
                  ? msg.mfaVerifyCall
                  : msg.mfaVerify
                : null}
              <span className="masked-factors">
                <br />
                {state != null ? state.data : null}
              </span>
            </p>
          </div>

          <div>
            <Label error={error}>{label.mfaVerify}</Label>
            <InputNumber
              id="otp-input"
              value={code}
              name="number"
              minLength={state != null ? (state.type === "call" ? 5 : 6) : null}
              maxLength={state != null ? (state.type === "call" ? 5 : 6) : null}
              noErrorsOrHelper
              autoFocus
              error={error}
              onChange={handleChange}
              setValue={setCode}
              data-testid="test-otp-input"
              hideAlertIcon
              description={
                state != null
                  ? state.type === "call"
                    ? extraMsg.voiceMfaCode
                    : extraMsg.mfaCode
                  : null
              }
              allowZeroFirst
            />
            <Button
              type="submit"
              onClick={handleSubmit}
              size="medium"
              className="button-style-fp"
            >
              {button.verifyCode}
            </Button>
          </div>

          <div className="resend-code">
            <p>{label.needCode}</p>
            <button
              id="resend-button"
              disabled={buttonDisabled}
              onClick={handleResendCode}
            >
              {state != null
                ? state.type === "call"
                  ? linkText.callAgain
                  : linkText.sendAgain
                : null}
            </button>
          </div>
          {state != null ? (
            state.countFactors > 1 ? (
              <div className="back-to-factors">
                <Button
                  variant="link"
                  icon="back"
                  size="small"
                  style={{ padding: "0", margin: "0" }}
                  onClick={handleGoBack}
                >
                  {linkText.backToFactors}
                </Button>
              </div>
            ) : null
          ) : null}
        </div>
      )}

      <div className="centre-align out-of-box">
        {state != null ? (
          state.navigateHref === href.updateEmail ? (
            helpLink == null ? null : (
              <HelpLink helpLink={helpLink} />
            )
          ) : (
            <div>
              <p className="p-center-align">Not able to receive codes?</p>
              <Button
                variant="link"
                size="small"
                style={{ padding: "0", margin: "0" }}
                onClick={handleSecurityQuestion}
              >
                {linkText.answerSecurityQuestion}
              </Button>
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};

export default MfaVerify;
