import React, { useEffect, useState } from "react";
import { formTitle } from "../../text/form-title.text";
import { title } from "../../text/page-title.text";
import {
  InputDropdown,
  InputBase,
  Title,
  Button,
  ProgressIndicator,
  Label,
} from "@creditonebank/c1b-components";
import { api } from "../../utils/api";
import { button } from "../../text/button.text";
import { href } from "../../utils/ui-urls";
import { useLocation, useNavigate } from "react-router-dom";
import { msg } from "../../text/form-message.text";
import { placeholder } from "../../text/placeholders";
import { label } from "../../text/labels.text";
import icon from "../../assets/SecurityIcon.svg";
import Alert from "../../components/alert/alert.component";
import { LoaderComponent } from "../../components/loader/loader.component";
import { errorMsg } from "../../text/error-message.text";
import { axiosInstance } from "../../service/https.service";
import { checkServerError } from "../../functions/check-server-error.function";
import { checkStateBaseAPI } from "../../functions/check-state.function";
import { HelpLink } from "../../components/link/help-link.component";

const EnrollSecurityQuestions = ({
  baseAPI,
  stepNumber,
  stepNames,
  mobileStepNames,
  helpLink,
}) => {
  useEffect(() => {
    fetchSecurityQuestions();
  }, []);

  const [securityQuestionsSetA, setSecurityQuestionsSetA] = useState([]);
  const [securityQuestionsSetB, setSecurityQuestionsSetB] = useState([]);
  const [securityQuestionsSetC, setSecurityQuestionsSetC] = useState([]);

  const [securityQuestionA, setSecurityQuestionA] = useState("");
  const [securityQuestionAError, setSecurityQuestionAError] = useState(false);
  const [securityQuestionB, setSecurityQuestionB] = useState("");
  const [securityQuestionBError, setSecurityQuestionBError] = useState(false);
  const [securityQuestionC, setSecurityQuestionC] = useState("");
  const [securityQuestionCError, setSecurityQuestionCError] = useState(false);

  const [securityAnswerA, setSecurityAnswerA] = useState("");
  const [securityAnswerAError, setSecurityAnswerAError] = useState(false);
  const [securityAnswerB, setSecurityAnswerB] = useState("");
  const [securityAnswerBError, setSecurityAnswerBError] = useState(false);
  const [securityAnswerC, setSecurityAnswerC] = useState("");
  const [securityAnswerCError, setSecurityAnswerCError] = useState(false);

  const [errorTitle, setErrorTitle] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [error, setError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  const fetchSecurityQuestions = async () => {
    try {
      const response = await axiosInstance.get(
        baseAPI + api.getAllSecurityQuestions
      );
      const Questions = response.data;

      for (let i = 0; i < 22; i++) {
        if (Questions[i].group === "GROUP A") {
          setSecurityQuestionsSetA((questionGroupA) => [
            ...questionGroupA,
            Questions[i].text,
          ]);
        } else if (Questions[i].group === "GROUP B") {
          setSecurityQuestionsSetB((questionGroupB) => [
            ...questionGroupB,
            Questions[i].text,
          ]);
        } else if (Questions[i].group === "GROUP C") {
          setSecurityQuestionsSetC((questionGroupC) => [
            ...questionGroupC,
            Questions[i].text,
          ]);
        }
      }
    } catch (error) {
      if (checkServerError(error.response.status)) {
        setShowLoader(false);
        setErrorTitle(errorMsg.internalServer.errorTitle);
        setErrorDescription(errorMsg.internalServer.errorDesc);
        setError(true);
      }
    }
  };

  useEffect(() => {
    document.title = title.enrollSecurityQuestions;
    window.scrollTo(0, 0);
    if (checkStateBaseAPI(state, baseAPI)) {
      window.location.href = "/";
    }
  }, []);

  function handleChange() {
    setError(false);
    setSecurityQuestionAError(false);
    setSecurityQuestionBError(false);
    setSecurityQuestionCError(false);
    setSecurityAnswerAError(false);
    setSecurityAnswerBError(false);
    setSecurityAnswerCError(false);
  }

  function handleSubmitAnswers(e) {
    e.preventDefault();
    setErrorTitle("");
    setErrorDescription("");
    setError(false);
    setSecurityQuestionAError(false);
    setSecurityQuestionBError(false);
    setSecurityQuestionCError(false);
    setSecurityAnswerAError(false);
    setSecurityAnswerBError(false);
    setSecurityAnswerCError(false);

    const securityQuestions = [
      securityQuestionA,
      securityAnswerA,
      securityQuestionB,
      securityAnswerB,
      securityQuestionC,
      securityAnswerC,
    ];

    if (securityQuestions.some((securityQuestion) => securityQuestion === "")) {
      setErrorTitle(errorMsg.missingSecurityQuestionsAnswers.errorTitle);
      setErrorDescription(errorMsg.missingSecurityQuestionsAnswers.errorDesc);
      if (securityQuestionA === "") {
        setSecurityQuestionAError(true);
      }
      if (securityAnswerA === "") {
        setSecurityAnswerAError(true);
      }
      if (securityQuestionB === "") {
        setSecurityQuestionBError(true);
      }
      if (securityAnswerB === "") {
        setSecurityAnswerBError(true);
      }
      if (securityQuestionC === "") {
        setSecurityQuestionCError(true);
      }
      if (securityAnswerC === "") {
        setSecurityAnswerCError(true);
      }
      window.scrollTo(0, 0);
    } else {
      setShowLoader(true);
      axiosInstance
        .put(baseAPI + state.id + api.updateSecurityQuestions, [
          {
            id: securityQuestionsSetA.indexOf(securityQuestionA) + 1,
            group: "Group A",
            text: securityQuestionA,
            answer: securityAnswerA,
          },
          {
            id: securityQuestionsSetB.indexOf(securityQuestionB) + 8,
            group: "Group B",
            text: securityQuestionB,
            answer: securityAnswerB,
          },
          {
            id: securityQuestionsSetC.indexOf(securityQuestionC) + 15,
            group: "Group C",
            text: securityQuestionC,
            answer: securityAnswerC,
          },
        ])
        .then((response) => {
          setShowLoader(false);
          setError(false);
          if (response.data) {
            navigate(href.accountActivationSuccess, { replace: true });
          }
        })
        .catch((error) => {
          if (checkServerError(error.response.status)) {
            setShowLoader(false);
            setErrorTitle(errorMsg.internalServer.errorTitle);
            setErrorDescription(errorMsg.internalServer.errorDesc);
            setError(true);
          } else {
            setShowLoader(false);
            setErrorTitle(error.response.data.error.title);
            setErrorDescription(error.response.data.error.message);
            setError(true);
          }
        });
    }
  }

  return (
    <div className="bg">
      <div className="progress-stepper-style">
        <ProgressIndicator
          className="web-progress-stepper"
          currentIndex={stepNumber}
          stepNames={stepNames}
        />
        <ProgressIndicator
          className="mobile-progress-stepper"
          currentIndex={stepNumber}
          stepNames={mobileStepNames}
        />
      </div>
      {showLoader ||
      securityQuestionsSetA === undefined ||
      securityQuestionsSetA.length === 0 ||
      securityQuestionsSetB === undefined ||
      securityQuestionsSetB.length === 0 ||
      securityQuestionsSetC === undefined ||
      securityQuestionsSetC.length === 0 ? (
        <LoaderComponent />
      ) : (
        <div className="form-element-activate-user">
          <div className="set-up-icon">
            <img src={icon} alt="security-icon" />
          </div>
          <div className="title">
            <Title align="center" tag="h2">
              {formTitle.enrollSecurityQuestions}
            </Title>
          </div>

          <div className="feedback-alert">
            <Alert
              alertType="error"
              title={errorTitle}
              description={errorDescription}
              showAlert={
                error ||
                securityQuestionAError ||
                securityQuestionBError ||
                securityQuestionCError ||
                securityAnswerAError ||
                securityAnswerBError ||
                securityAnswerCError
              }
            />
          </div>

          <div className="description">
            <p className="desc2">{msg.enrollSecurityQuestions}</p>
          </div>

          <div className="dropdown-style">
            <Label error={error || securityQuestionAError}>
              {label.securityQuestion1}
            </Label>
            <InputDropdown
              id="dd"
              name="dropdown-input"
              setValue={setSecurityQuestionA}
              type="dropdown"
              placeholder={placeholder.defaultSecurityQuestion}
              value={securityQuestionA}
              options={securityQuestionsSetA}
              noErrorsOrHelper
              hideAlertIcon
              error={error || securityQuestionAError}
              onChange={handleChange}
              autoFocus
              data-testid="test-securityQuestionA"
            />

            <InputBase
              id="securityAnswerA"
              name="securityAnswer-input"
              setValue={setSecurityAnswerA}
              type="securityAnswer"
              placeholder={placeholder.defaultSecurityAnswer}
              value={securityAnswerA}
              error={error || securityAnswerAError}
              onChange={handleChange}
              noErrorsOrHelper
              hideAlertIcon
              data-testid="test-securityAnswerA"
            />

            <hr className="dashed" />

            <Label error={error || securityQuestionBError}>
              {label.securityQuestion2}
            </Label>
            <InputDropdown
              id="dd"
              name="dropdown-input"
              setValue={setSecurityQuestionB}
              type="dropdown"
              placeholder={placeholder.defaultSecurityQuestion}
              value={securityQuestionB}
              options={securityQuestionsSetB}
              error={error || securityQuestionBError}
              onChange={handleChange}
              noErrorsOrHelper
              hideAlertIcon
              data-testid="test-securityQuestionB"
            />

            <InputBase
              id="securityAnswerB"
              name="securityAnswer-input"
              setValue={setSecurityAnswerB}
              type="securityAnswer"
              placeholder={placeholder.defaultSecurityAnswer}
              value={securityAnswerB}
              error={error || securityAnswerBError}
              onChange={handleChange}
              noErrorsOrHelper
              hideAlertIcon
              data-testid="test-securityAnswerB"
            />

            <hr className="dashed" />

            <Label error={error || securityQuestionCError}>
              {label.securityQuestion3}
            </Label>
            <InputDropdown
              id="dd"
              name="dropdown-input"
              setValue={setSecurityQuestionC}
              type="dropdown"
              placeholder={placeholder.defaultSecurityQuestion}
              value={securityQuestionC}
              options={securityQuestionsSetC}
              error={error || securityQuestionCError}
              onChange={handleChange}
              noErrorsOrHelper
              hideAlertIcon
              data-testid="test-securityQuestionC"
            />

            <InputBase
              id="securityAnswerC"
              name="securityAnswer-input"
              setValue={setSecurityAnswerC}
              type="securityAnswer"
              placeholder={placeholder.defaultSecurityAnswer}
              value={securityAnswerC}
              error={error || securityAnswerCError}
              onChange={handleChange}
              noErrorsOrHelper
              hideAlertIcon
              data-testid="test-securityAnswerC"
            />
          </div>

          <Button
            type="submit"
            onClick={handleSubmitAnswers}
            size="medium"
            className="button-style-3"
          >
            {button.enrollSecurityQuestions}
          </Button>
        </div>
      )}

      <div className="centre-align out-of-box">
        <HelpLink helpLink={helpLink} />
      </div>
    </div>
  );
};

export default EnrollSecurityQuestions;
