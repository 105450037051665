import React, { useEffect } from "react";
import { successMsg } from "../../text/success.text";
import { msg } from "../../text/form-message.text";
import { title } from "../../text/page-title.text";
import { formTitle } from "../../text/form-title.text";
import { Title } from "@creditonebank/c1b-components";
import Alert from "../../components/alert/alert.component";
import { useLocation } from "react-router-dom";
import { checkState } from "../../functions/check-state.function";

const ResendActivationSuccess = () => {
  useEffect(() => {
    document.title = title.resendActivationSuccess;
    window.scrollTo(0, 0);
    if (checkState(state)) {
      window.location.href = "/";
    }
  }, []);

  const { state } = useLocation();

  return (
    <div className="bg">
      <div className="form-element-resend-success">
        <div className="title">
          <Title tag="h2" className="web-title">
            {formTitle.resendActivationSuccess}
          </Title>
          <Title tag="h2" className="mobile-title" align="center">
            {formTitle.resendActivationSuccess}
          </Title>
        </div>

        <div className="feedback-success">
          <Alert
            alertType="success"
            title={successMsg.resendActivationSuccess1(state.email)}
            showAlert={true}
          />
        </div>

        <p className="inform">{msg.resendActivationSuccess}</p>
      </div>
    </div>
  );
};

export default ResendActivationSuccess;
