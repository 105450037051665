import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect } from "react";
import { LoaderComponent } from "../loader/loader.component";
import { href } from "../../utils/ui-urls";

const Login = () => {
  const { authState, oktaAuth } =  useOktaAuth();

  const loginCallback = async () => {
    try {
      const { tokens } = await oktaAuth.token.parseFromUrl(); // remember to "await" this async call
      oktaAuth.tokenManager.setTokens(tokens);
    } catch (e) {
      console.log("Okta Callback Error");
      // log or display error details
    }
  };

  const authentication = async () => {
    if (!(await oktaAuth.isAuthenticated())) {
      // Start the browser based oidc flow, then parse tokens from the redirect callback url
      await oktaAuth.signInWithRedirect();
    } else {
      window.location.replace(href.dashboard)
      // User is authenticated
    }
  }

  useEffect(() => {
    if (oktaAuth.isLoginRedirect()) {
      loginCallback()
    } else {
      authentication()
    }
  }, [])

  useEffect(() => {
    if(authState && authState?.isAuthenticated) {
      authentication()
    }  
  }, [authState])

  return (
    <div className="bg">
      <LoaderComponent />
    </div>
  );
};

export default Login;
