import React, { useEffect, useState } from "react";
import { msg } from "../../text/form-message.text";
import { formTitle } from "../../text/form-title.text";
import { button } from "../../text/button.text";
import { api } from "../../utils/api";
import { title } from "../../text/page-title.text";
import { Title, Button } from "@creditonebank/c1b-components";
import { href } from "../../utils/ui-urls";
import { errorMsg } from "../../text/error-message.text";
import { useNavigate, useLocation } from "react-router-dom";
import Alert from "../../components/alert/alert.component";
import { LoaderComponent } from "../../components/loader/loader.component";
import { axiosInstance } from "../../service/https.service";
import { errorResponse } from "../../text/error.responses.text";
import { HelpLink } from "../../components/link/help-link.component";
import { checkServerError } from "../../functions/check-server-error.function";
import { checkState } from "../../functions/check-state.function";

const TokenExpired = ({ baseAPI, helpLink }) => {
  const [error, setError] = useState(false);
  const [errorTitle, setErrorTitle] = useState();
  const [errorDescription, setErrorDescription] = useState("");
  const [showMsg, setShowMsg] = useState(true);
  const { state } = useLocation();
  const [showLoader, setShowLoader] = useState(false);
  const linkExpiredErrorMsg = errorMsg.tokenExpired;
  const navigate = useNavigate();

  useEffect(() => {
    document.title = title.tokenExpired;
    window.scrollTo(0, 0);
    // if (state === undefined || state === null || state === "") {
    if (checkState(state)) {
      window.location.href = "/";
    }
  }, []);

  const handleResendActivation = () => {
    setError(false);
    setShowLoader(true);
    axiosInstance
      .post(baseAPI + api.resendActivationMail, {
        emailId: state.emailId,
        governmentIdentifier: state.governmentIdentifier,
        globalPin: state.globalPin,
      })
      .then(() => {
        setShowLoader(false);
        navigate(href.resendActivationSuccess, {
          state: {
            email: state.emailId
          },
          replace: true
        });
      })
      .catch((error) => {
        if (checkServerError(error.response.status)) {
          setShowLoader(false);
          setErrorTitle(errorMsg.internalServer.errorTitle);
          setErrorDescription(errorMsg.internalServer.errorDesc);
          setError(true);
          window.scrollTo(0, 0);
        } else {
          setShowLoader(false);
          if (
            error.response.data.error.title ===
            errorResponse.accountAlreadyActiveError
          ) {
            navigate(href.accountActive);
          } else {
            setShowMsg(false);
            setErrorTitle(error.response.data.error.title);
            setErrorDescription(error.response.data.error.message);
            setError(true);
            window.scrollTo(0, 0);
          }
        }
      });
  };

  return (
    <div className="bg">
      {showLoader ? (
        <LoaderComponent />
      ) : (
        <div className="form-element-token-expired">
          <Title tag="h2">
            {formTitle.tokenExpired}
          </Title>

          <div className="feedback-alert">
            <Alert
              alertType="success"
              title={linkExpiredErrorMsg}
              showAlert={showMsg}
            />
          </div>

          <div className="feedback-alert">
            <Alert
              alertType="error"
              title={errorTitle}
              description={errorDescription}
              showAlert={error}
            />
          </div>

          <div className="description">
            <p className="desc2">{msg.tokenExpired}</p>
          </div>
          <Button
            onClick={handleResendActivation}
            size="medium"
            className="button-style-success"
            data-testid="test-btnResendActivation"
          >
            {button.resendActivation}
          </Button>
        </div>
      )}

      <div className="centre-align need-help-token-expired">
        <HelpLink helpLink={helpLink} />
      </div>
    </div>
  );
};

export default TokenExpired;
