export const passwordValidate = {
  specialCharactersRegex: /["!#$%&'()*+,-./\\:;<=>?@[\]^_`{|}~]+/g,
  regex: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/,
  passwordInvalid: {
    errorTitle: "Your password does not meet the requirements",
    errorDesc:
      "Please enter a password that meets the minimum requirements shown below.",
  },
  passwordsNoMatch: {
    errorTitle: "Passwords do not match",
    errorDesc: "Check that your passwords match and try again.",
  },
  passwordsNoMatchValidateUser: {
    errorTitle: "Couldn't reset password",
    errorDesc: "The passwords you entered below don't match. Please try again.",
  },
  passwordInvalidValidateUser: {
    errorTitle: "Couldn't reset password",
    errorDesc: "Make sure your password meets the minimum requirements below.",
  },
  blankValuesValidateUser2: {
    errorTitle: "We're missing required information",
    errorDesc: "Please enter a username and password.",
  },
  blankPasswordsValidateUser2: {
    errorTitle: "We're missing required information",
    errorDesc: "Please enter and re-enter your password.",
  },
  blankUsernameConfPass: {
    errorTitle: "We're missing required information",
    errorDesc: "Please enter a username and re-enter your password.",
  },
  missingUserameAndPassword: {
    errorTitle: "We're missing required information",
    errorDesc:
      "Please enter a username and check that your password meets the minimum requirements.",
  },
  blankConfirmPassword: {
    errorTitle: "We're missing required information",
    errorDesc: "Please re-enter your new password and try again.",
  },
};
