import { Button } from "@creditonebank/c1b-components";
import { ReactComponent as FailIcon } from "../../assets/Fail.svg";
import React from "react";

const SessionTimedOut = () => {

  const signInWidget = (() => {
    window.location.replace("/")
  })

  return (
    <div className="bg">
      <div className="session-timeout-popup">
        <div className="popup-content">
          <div className="timer">
            <FailIcon className="fail" />
            <h4>Your session has expired</h4>
            <p className="subtext">We have signed you out to protect your account. To access your account, please sign in again.</p>
          </div>
          <div className="buttons">
            <Button icon="lock" onClick={signInWidget} className="sign-in">Sign in</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SessionTimedOut