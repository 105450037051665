export const withoutGlobalPinMsg = {
  forgotPassword:
    "To make sure it's really you, we'll need your username and Social Security number.",
  forgotUsername:
    "To make sure it's really you, we'll need your email address and Social Security number.",
  resendActivationMail:
    "To get started, verify your identity by entering your email address and Social Security number below.",
  resendActivation:
    "To get started, verify your identity by entering your phone number and Social Security number below.",
  validateActivation:
    "To get started, verify your identity by entering your Social Security number.",
  resetMfa:
    "First, we need to make sure it’s really you. Please enter your username and Social Security number.",
  unlockAccount:
    "To unlock your account, we need to make sure it’s you. Enter your username and Social Security number.",
};
