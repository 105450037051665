export const api = {
  forgotPassword: "forgot-password",
  resetPassword: "/reset-password",

  forgotUsername: "forgot-username",
  getRandomSecurityQuestion: "/securityquestions",
  validateSecurityQuestion: "/validate/securityquestions",

  resetMfa: "factors/reset-mfa",
  resetAll: "/factors",

  validateActivation: "validate-activation",
  activateUser: "/activate-user",
  getAllSecurityQuestions: "securityquestions",
  updateSecurityQuestions: "/securityquestions",
  resendActivationMail: "resend-activation-mail",
  updateEmail: "/email",

  unlockAccount: "unlock-account",
};
