export const successMsg = {
  resendActivationSuccess: "We sent you an activation email",
  resendActivationSuccess1: (email) => {
    if (email == null) {
      return "We sent you an activation email"
    }
    let sliceEmail = email.indexOf("@");
    let username = email.slice(0, sliceEmail);
    let domain = email.slice(sliceEmail);
    let formatEmail = `${username.charAt(0)}${"*".repeat(
      5
    )}${username.charAt(username.length - 1)}${domain}`;
    formatEmail = formatEmail.toLowerCase();
    return "We sent you an activation email to " + formatEmail
  },
  unlockAccountSuccess: "Your account has been unlocked",
  usernameText: "Your username is: ",
  codeResent: "We sent you a new code",
};
