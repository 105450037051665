export const formTitle = {
  forgotPassword: "Forgot Password?",
  accountNotActive: "Your account hasn’t been activated yet",
  mfaChallenge: "Verify Your Identity",
  forgotPasswordSecurity: "Reset Password",
  resetPassword: "Reset Password",
  resetPasswordSuccess: "Password Reset",

  forgotUsername: "Forgot Username?",
  forgotUsername2: "Forgot Username?",
  forgotUsernameSuccess: "We Found You!",

  resendActivationMail: "Welcome to",
  resendActivationMail2: "Credit One Bank!",
  updateEmail: "Activate Your Account",
  resendActivationSuccess: "Online Account Setup",

  validateActivation: "Welcome to",
  validateUser1: "Credit One Bank!",
  activateUser: "Set Up Online",
  activateUser2a: "Account Access",
  tokenExpired: "Online account set up",
  enrollSecurityQuestions: "Set Up Security Questions",
  accountActivationSuccess: "Your Account is Ready",

  unlockAccount: "Unlock Account",
  unlockAccountSuccess: "Account Unlocked",

  resetMfaInfo: "Reset Two-Step Authentication",
  resetMfa: "Reset Two-Step Authentication",
  securityQuestionMfa: "Reset Two-Step Authentication",
  confirmResetMfa: "Confirm Two-Factor Authentication Reset",
  resetMfaSuccess: "Two-Step",
  resetMfaSuccess2: "Authentication Reset",

  sessionExpired: "Your Session has expired",
  requestError: "Sorry, we couldn't complete your request",
  accountUnlockedError: "Your account is already unlocked",
  accountActive: "Your account is already active",
  resetMfaError: "We couldn’t reset two-step authentication",
};
