import React, { useEffect, useState } from "react";
import { api } from "../../utils/api";
import { title } from "../../text/page-title.text";
import { extraMsg, msg } from "../../text/form-message.text";
import { button } from "../../text/button.text";
import { useLocation, useNavigate } from "react-router-dom";
import { href } from "../../utils/ui-urls";
import { linkText } from "../../text/links.text";
import { formTitle } from "../../text/form-title.text";
import Tabs from "../../components/tabs/tabs.component";
import {
  InputSocialSecurity,
  InputOTP,
  Button,
  Title,
  ProgressIndicator,
  Label,
} from "@creditonebank/c1b-components";
import { label } from "../../text/labels.text";
import { headline } from "../../text/form-headline.text.js";
import { withoutGlobalPinMsg } from "../../text/messages-without-globalpin.text";
import Alert from "../../components/alert/alert.component";
import { LoaderComponent } from "../../components/loader/loader.component";
import { errorMsg } from "../../text/error-message.text";
import { axiosInstance } from "../../service/https.service";
import { checkServerError } from "../../functions/check-server-error.function";
import { checkState } from "../../functions/check-state.function";

const ValidateActivation = ({
  baseAPI,
  signInUrl,
  globalPinFlag,
  stepNumber,
  stepNames,
  mobileStepNames,
}) => {
  useEffect(() => {
    document.title = title.validateActivation;
    window.scrollTo(0, 0);
    document.getElementById("governmentIdentifier__input__adv__helper")?.setAttribute("x-apple-data-detectors", false);
    if (checkState(location.state)) {
      window.location.href = "/";
    }
  }, []);

  const location = useLocation();
  const [individualUniqueIdentifier, setIndividualUniqueIdentifier] =
    useState(null);
  const [token, setToken] = useState(null);
  const [error, setError] = useState(false);
  const [governmentIdentifier, setGovernmentIdentifier] = useState("");
  const [ssnError, setSsnError] = useState(false);
  const [globalPin, setGlobalPin] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state) {
      const individualUniqueIdentifier =
        location.state.individualUniqueIdentifier;
      const token = location.state.token;
      setIndividualUniqueIdentifier(individualUniqueIdentifier);
      setToken(token);
    }
  }, [location]);

  function handleChange() {
    setSsnError(false);
    setError(false);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setErrorTitle("");
    setErrorDescription("");
    setError(false);
    setSsnError(false);

    if (governmentIdentifier === "") {
      setErrorTitle(errorMsg.missingGovtId.errorTitle);
      setErrorDescription(errorMsg.missingGovtId.errorDesc);
      setSsnError(true);
      window.scrollTo(0, 0);
    } else {
      setShowLoader(true);
      axiosInstance
        .post(
          baseAPI + api.validateActivation,
          {
            individualUniqueIdentifier: individualUniqueIdentifier,
            governmentIdentifier: governmentIdentifier,
            globalPin: globalPin,
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then((response) => {
          setShowLoader(false);
          if (response.data.tokenExpired === true) {
            navigate(href.tokenExpired, {
              state: {
                emailId: response.data.email,
                governmentIdentifier: governmentIdentifier,
                globalPin: globalPin,
              },
            });
          } else {
            setError(false);
            navigate(href.activateUser, { state: { id: response.data.id } });
          }
        })
        .catch((error) => {
          if (checkServerError(error.response.status)) {
            setShowLoader(false);
            setErrorTitle(errorMsg.internalServer.errorTitle);
            setErrorDescription(errorMsg.internalServer.errorDesc);
            setError(true);
            window.scrollTo(0, 0);
          } else if (
            error.response.data.error.title ===
            errorMsg.errorValidationActivation.errorTitle
          ) {
            setShowLoader(false);
            setErrorTitle(error.response.data.error.title);
            setErrorDescription(errorMsg.errorValidationActivation.errorDesc);
            setError(true);
            window.scrollTo(0, 0);
          } else {
            setShowLoader(false);
            setErrorTitle(error.response.data.error.title);
            setErrorDescription(error.response.data.error.message);
            setError(true);
            window.scrollTo(0, 0);
          }
        });
    }
  }

  const handleSignIn = () => {
    window.location.href = signInUrl;
  };

  return (
    <div className="bg">
      <div className="progress-stepper-style validate-progress">
        <ProgressIndicator
          className="web-progress-stepper"
          currentIndex={stepNumber}
          stepNames={stepNames}
        />
        <ProgressIndicator
          className="mobile-progress-stepper"
          currentIndex={stepNumber}
          stepNames={mobileStepNames}
        />
      </div>
      {showLoader || individualUniqueIdentifier == null || token == null ? (
        <LoaderComponent />
      ) : (
        <div className="div-container-activation">
          <div className="form-element-activation">
            <form onSubmit={(e) => handleSubmit(e)} className="fp-form">
              <div className="title">
                <Title tag="h2" className="web-title">
                  {formTitle.validateActivation}
                </Title>
                <Title tag="h2" className="web-title">
                  {" "}
                  {formTitle.validateUser1}
                </Title>

                <Title tag="h2" className="mobile-title" align="center">
                  {formTitle.validateActivation}
                </Title>
                <Title tag="h2" className="mobile-title" align="center">
                  {" "}
                  {formTitle.validateUser1}
                </Title>
              </div>

              <div className="feedback-alert">
                <Alert
                  alertType="error"
                  title={errorTitle}
                  description={errorDescription}
                  showAlert={error || ssnError}
                />
              </div>

              <div className="description">
                <p className="headline-style">{headline.validateActivation}</p>
                <p className="desc2">
                  {globalPinFlag
                    ? msg.validateActivation
                    : withoutGlobalPinMsg.validateActivation}
                </p>
              </div>

              {globalPinFlag ? (
                <Tabs>
                  <div label={label.governmentIdentifier}>
                    <InputSocialSecurity
                      id="governmentIdentifier"
                      value={governmentIdentifier}
                      name="governmentIdentifier-input"
                      setValue={setGovernmentIdentifier}
                      error={error || ssnError}
                      noErrorsOrHelper
                      hideAlertIcon
                      autoFocus
                      description={extraMsg.exampleSSN}
                      onChange={handleChange}
                      data-testid="test-governmentIdentifier"
                    />
                  </div>

                  <div label={label.globalPin}>
                    <InputOTP
                      id="globalPin"
                      value={globalPin}
                      name="globalPin-input"
                      setValue={setGlobalPin}
                      error={error}
                      description={extraMsg.exampleGlobalPin}
                      noErrorsOrHelper
                      hideAlertIcon
                      onChange={handleChange}
                    />
                  </div>
                </Tabs>
              ) : (
                <>
                  <Label error={error || ssnError}>
                    {label.governmentIdentifier}
                  </Label>
                  <InputSocialSecurity
                    id="governmentIdentifier"
                    value={governmentIdentifier}
                    name="governmentIdentifier-input"
                    setValue={setGovernmentIdentifier}
                    error={error || ssnError}
                    noErrorsOrHelper
                    hideAlertIcon
                    autoFocus
                    description={extraMsg.exampleSSN}
                    onChange={handleChange}
                    data-testid="test-governmentIdentifier"
                  />
                </>
              )}

              <Button
                type="submit"
                onClick={handleSubmit}
                size="medium"
                className="button-style"
              >
                {button.validateActivation}
              </Button>
            </form>
          </div>
          <div className="activation-image"></div>
        </div>
      )}

      <div className="centre-align out-of-box">
        <p>
          {extraMsg.existingCardMember}
          <Button
            variant="link"
            size="small"
            onClick={handleSignIn}
            style={{ padding: "0", lineHeight: "2rem", marginBottom: "15px" }}
          >
            {linkText.signIn}
          </Button>
        </p>
      </div>
    </div>
  );
};

export default ValidateActivation;
