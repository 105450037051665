export const errorResponse = {
  passwordError: "Your password does not meet the requirements",
  newUsernameError: "Username already exists",
  newUsernameValidationError: "Your username has characters that are not allowed",
  accountNotActive: "Your account is not active",
  problemProcessingRequest: "Problem Processing Your Request",
  unlockAcountError: "Your account is not locked out",
  accountAlreadyUnlockedError: "Your account is already unlocked",
  accountAlreadyActiveError: "Account is already activated",
  accountSuspended: "Your account is suspended",
  accountLocked: "Your account has been locked",
};
