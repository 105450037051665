import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { successMsg } from "../../text/success.text";
import { extraMsg } from "../../text/form-message.text";
import { formTitle } from "../../text/form-title.text";
import { button } from "../../text/button.text";
import { Button, Title } from "@creditonebank/c1b-components";
import { title } from "../../text/page-title.text";
import { checkState } from "../../functions/check-state.function";

const ForgotUsernameSuccess = ({ signInUrl }) => {
  useEffect(() => {
    document.title = title.forgotUsernameSuccess;
    window.scrollTo(0, 0);
    if (checkState(state)) {
      window.location.href = "/";
    }
  }, []);

  const { state } = useLocation();
  const handleSignIn = () => {
    window.location.href = signInUrl;
  };

  return (
    <div className="bg">
      <div className="form-element-username-success">
        <div className="title">
          <Title tag="h2" className="web-title">
            {formTitle.forgotUsernameSuccess}
          </Title>

          <Title tag="h2" className="mobile-title" align="center">
            {formTitle.forgotUsernameSuccess}
          </Title>
        </div>

        <div className="description">
          <p className="desc2">
            {successMsg.usernameText}
            <p className="headline-style inline-message">
              {state != null ? state.login : null}
            </p>
          </p>
          <p className="desc2">{extraMsg.forgotUsernameSuccess}</p>
        </div>

        <Button
          onClick={handleSignIn}
          className="button-style-success"
          size="medium"
        >
          {button.signIn}
        </Button>
      </div>
    </div>
  );
};

export default ForgotUsernameSuccess;
