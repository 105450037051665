import "./loader.component.css";

export const LoaderComponent = () => {
  return (
    <div className="loader">
      <div id="wrapper_loader" className="loader-fadein">
        <div className="loader-ani1">
          <div className="circle"></div>
        </div>
      </div>
    </div>
  );
};
