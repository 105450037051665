import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { title } from "../../text/page-title.text";
import { extraMsg, msg } from "../../text/form-message.text";
import { button } from "../../text/button.text";
import { href } from "../../utils/ui-urls";
import { useNavigate } from "react-router-dom";
import { linkText } from "../../text/links.text";
import { formTitle } from "../../text/form-title.text";
import { headline } from "../../text/form-headline.text.js";
import Tabs from "../../components/tabs/tabs.component";
import {
  InputBase,
  InputSocialSecurity,
  InputOTP,
  Button,
  Title,
  Label,
} from "@creditonebank/c1b-components";
import { label } from "../../text/labels.text";
import { withoutGlobalPinMsg } from "../../text/messages-without-globalpin.text";
import Alert from "../../components/alert/alert.component";
import { LoaderComponent } from "../../components/loader/loader.component";
import { errorMsg } from "../../text/error-message.text";
import { errorResponse } from "../../text/error.responses.text";
import { axiosInstance } from "../../service/https.service";
import { checkServerError } from "../../functions/check-server-error.function";
import { BackToSignIn } from "../../components/link/back-to-signin.component";
import { HelpLink } from "../../components/link/help-link.component";

const ForgotPassword = ({ baseAPI, signInUrl, globalPinFlag, helpLink }) => {
  useEffect(() => {
    document.title = title.forgotPassword;
    document.getElementById("governmentIdentifier__input__adv__helper")?.setAttribute("x-apple-data-detectors", false);
    window.scrollTo(0, 0);
  }, []);

  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [governmentIdentifier, setGovernmentIdentifier] = useState("");
  const [ssnError, setSsnError] = useState(false);
  const [globalPin, setGlobalPin] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [error, setError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  function handleSubmit(e) {
    e.preventDefault();
    setErrorTitle("");
    setErrorDescription("");
    setError(false);
    setUsernameError(false);
    setSsnError(false);

    if (
      username === "" &&
      governmentIdentifier === "" &&
      globalPinFlag === false
    ) {
      setErrorTitle(errorMsg.missingUsernameAndGovtId.errorTitle);
      setErrorDescription(errorMsg.missingUsernameAndGovtId.errorDesc);
      setError(true);
      window.scrollTo(0, 0);
    } else if (username === "") {
      setErrorTitle(errorMsg.missingUsernameValue.errorTitle);
      setErrorDescription(errorMsg.missingUsernameValue.errorDesc);
      setUsernameError(true);
      window.scrollTo(0, 0);
    } else if (governmentIdentifier === "") {
      setErrorTitle(errorMsg.missingGovtId.errorTitle);
      setErrorDescription(errorMsg.missingGovtId.errorDesc);
      setSsnError(true);
      window.scrollTo(0, 0);
    } else {
      setShowLoader(true);
      axiosInstance
        .post(baseAPI + api.forgotPassword, {
          login: username,
          governmentIdentifier: governmentIdentifier,
          globalPin: globalPin,
        })
        .then((response) => {
          setShowLoader(false);
          setError(false);
          if (response.data.mfaAvailable) {
            let phone = response.data.phone;
            let email = response.data.email;
            let primaryPhone = response.data.primaryPhone;

            let formatPrimaryPhone;
            let formatMobile;
            let formatEmail;

            //formatting and masking phone number
            if (phone !== null && phone !== "" && phone !== undefined) {
              formatMobile = `${phone.slice(0, 3)} ${"*".repeat(5)} ${"*".repeat(1) + phone.slice(-4)
                } `;
            }

            //formatting and masking primary phone number
            if (
              primaryPhone !== null &&
              primaryPhone !== "" &&
              primaryPhone !== undefined
            ) {
              formatPrimaryPhone = `${primaryPhone.slice(0, 3)} ${"*".repeat(
                5
              )} ${"*".repeat(1) + primaryPhone.slice(-4)} `;
            }

            //masking email
            if (email !== null && email !== "" && email !== undefined) {
              let sliceEmail = email.indexOf("@");
              let username = email.slice(0, sliceEmail);
              let domain = email.slice(sliceEmail);
              formatEmail = `${username.charAt(0)}${"*".repeat(
                3
              )}${username.charAt(username.length - 1)}${domain}`;
              formatEmail = formatEmail.toLowerCase();
            }

            navigate(href.mfaChallenge, {
              state: {
                id: response.data.id,
                factors: response.data.factors,
                email: formatEmail,
                phone: formatMobile,
                primaryPhone: formatPrimaryPhone,
                securityQuestionsAvailable:
                  response.data.securityQuestionsAvailable,
                navigateHref: href.resetPassword,
              },
            });
          } else if (response.data.securityQuestionsAvailable) {
            navigate(href.forgotPasswordSecurity, {
              state: {
                id: response.data.id,
              },
            });
          } else {
            navigate(href.requestError);
          }
        })
        .catch((error) => {
          if (checkServerError(error.response.status)) {
            setShowLoader(false);
            setErrorTitle(errorMsg.internalServer.errorTitle);
            setErrorDescription(errorMsg.internalServer.errorDesc);
            setError(true);
            window.scrollTo(0, 0);
          } else {
            setShowLoader(false);
            if (
              error.response.data.error.title === errorResponse.accountNotActive
            ) {
              navigate(href.accountNotActive);
            } else if (
              error.response.data.error.title ===
              errorResponse.problemProcessingRequest ||
              error.response.data.error.title === errorResponse.accountSuspended
            ) {
              navigate(href.requestError);
            } else {
              setErrorTitle(error.response.data.error.title);
              setErrorDescription(errorMsg.errorForgotPasswordDescFunc());
              setError(true);
              window.scrollTo(0, 0);
            }
          }
        });
    }
  }

  const handleChange = () => {
    setError(false);
    setUsernameError(false);
    setSsnError(false);
  };

  const handleForgotUsername = () => {
    navigate(href.forgotUsername);
  };

  return (
    <div className="bg">
      {showLoader ? (
        <LoaderComponent />
      ) : (
        <div className="form-element">
          <form onSubmit={(e) => handleSubmit(e)} className="fp-form">
            <div className="title">
              <Title tag="h2" className="web-title">
                {formTitle.forgotPassword}
              </Title>

              <Title tag="h2" className="mobile-title" align="center">
                {formTitle.forgotPassword}
              </Title>
            </div>

            <div className="feedback-alert">
              <Alert
                alertType="error"
                title={errorTitle}
                description={errorDescription}
                showAlert={error || usernameError || ssnError}
              />
            </div>

            <div className="description">
              <p className="headline-style">{headline.forgotPassword}</p>
              <p className="desc2">
                {globalPinFlag
                  ? msg.forgotPassword
                  : withoutGlobalPinMsg.forgotPassword}
              </p>
            </div>

            <div className="box-with-link">
              <Label error={error || usernameError}>{label.username}</Label>
              <InputBase
                id="username"
                value={username}
                name="username-input"
                setValue={setUsername}
                error={error || usernameError}
                minLength={6}
                maxLength={26}
                noErrorsOrHelper
                hideAlertIcon
                autoFocus
                onChange={handleChange}
                data-testid="test-username"
              />

              <Button
                variant="link"
                size="small"
                style={{ padding: "0", margin: "0" }}
                onClick={handleForgotUsername}
              >
                {linkText.forgotUsername}
              </Button>
            </div>

            <div className="govtId-input">
              {globalPinFlag ? (
                <Tabs>
                  <div label={label.governmentIdentifier}>
                    <InputSocialSecurity
                      id="governmentIdentifier"
                      value={governmentIdentifier}
                      name="governmentIdentifier-input"
                      setValue={setGovernmentIdentifier}
                      error={error || ssnError}
                      noErrorsOrHelper
                      hideAlertIcon
                      description={extraMsg.exampleSSN}
                      onChange={handleChange}
                      data-testid="test-governmentIdentifier"
                    />
                  </div>

                  <div label={label.globalPin}>
                    <InputOTP
                      id="globalPin"
                      value={globalPin}
                      type="password"
                      name="globalPin-input"
                      setValue={setGlobalPin}
                      error={error}
                      description={extraMsg.exampleGlobalPin}
                      noErrorsOrHelper
                      hideAlertIcon
                      onChange={handleChange}

                    />
                  </div>
                </Tabs>
              ) : (
                <>
                  <Label error={error || ssnError}>
                    {label.governmentIdentifier}
                  </Label>
                  <InputSocialSecurity
                    id="governmentIdentifier"
                    value={governmentIdentifier}
                    name="governmentIdentifier-input"
                    setValue={setGovernmentIdentifier}
                    error={error || ssnError}
                    noErrorsOrHelper
                    hideAlertIcon
                    description={extraMsg.exampleSSN}
                    onChange={handleChange}
                    data-testid="test-governmentIdentifier"
                  />
                </>
              )}
            </div>

            <Button
              type="submit"
              onClick={handleSubmit}
              size="medium"
              className="button-style-fp"
            >
              {button.forgotPassword}
            </Button>

            <div className="centre-align inside-box">
              <BackToSignIn signInUrl={signInUrl} />
            </div>
          </form>
        </div>
      )}

      <div className="centre-align out-of-box">
        <HelpLink helpLink={helpLink} />
      </div>
    </div>
  );
};

export default ForgotPassword;
