import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../utils/api";
import { title } from "../../text/page-title.text";
import { extraMsg, msg } from "../../text/form-message.text";
import { label } from "../../text/labels.text";
import { button } from "../../text/button.text";
import { href } from "../../utils/ui-urls";
import { linkText } from "../../text/links.text";
import { formTitle } from "../../text/form-title.text";
import Tabs from "../../components/tabs/tabs.component";
import {
  InputEmail,
  InputSocialSecurity,
  InputOTP,
  Button,
  Title,
  ProgressIndicator,
  Label,
} from "@creditonebank/c1b-components";
import { headline } from "../../text/form-headline.text.js";
import { withoutGlobalPinMsg } from "../../text/messages-without-globalpin.text";
import Alert from "../../components/alert/alert.component";
import { LoaderComponent } from "../../components/loader/loader.component";
import { errorMsg } from "../../text/error-message.text";
import { errorResponse } from "../../text/error.responses.text";
import { axiosInstance } from "../../service/https.service";
import { checkServerError } from "../../functions/check-server-error.function";

const ResendActivationMail = ({
  baseAPI,
  signInUrl,
  globalPinFlag,
  stepNumber,
  stepNames,
  mobileStepNames,
}) => {
  useEffect(() => {
    document.title = title.resendActivationMail;
    document.getElementById("governmentIdentifier__input__adv__helper")?.setAttribute("x-apple-data-detectors", false);
    window.scrollTo(0, 0);
  }, []);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [governmentIdentifier, setGovernmentIdentifier] = useState("");
  const [ssnError, setSsnError] = useState("");
  const [globalPin, setGlobalPin] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [error, setError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  function handleSubmit(e) {
    e.preventDefault();
    setErrorTitle("");
    setErrorDescription("");
    setError(false);
    setEmailError(false);
    setSsnError(false);

    if (
      email === "" &&
      governmentIdentifier === "" &&
      globalPinFlag === false
    ) {
      setError(true);
      setErrorTitle(errorMsg.missingEmailAndGovtId.errorTitle);
      setErrorDescription(errorMsg.missingEmailAndGovtId.errorDesc);
      window.scrollTo(0, 0);
    } else if (email === "") {
      setErrorTitle(errorMsg.missingEmailValue.errorTitle);
      setErrorDescription(errorMsg.missingEmailValue.errorDesc);
      setEmailError(true);
      window.scrollTo(0, 0);
    } else if (governmentIdentifier === "") {
      setErrorTitle(errorMsg.missingGovtId.errorTitle);
      setErrorDescription(errorMsg.missingGovtId.errorDesc);
      setSsnError(true);
      window.scrollTo(0, 0);
    } else {
      setShowLoader(true);
      axiosInstance
        .post(
          baseAPI + api.resendActivationMail,
          {
            emailId: email,
            governmentIdentifier: governmentIdentifier,
            globalPin: globalPin,
          },
          {
            headers: {
              accessEmail: true,
            },
          }
        )
        .then((response) => {
          setShowLoader(false);
          navigate(href.resendActivationSuccess, {
            state: {
              email: email
            }
          });
        })
        .catch((error) => {
          if (checkServerError(error.response.status)) {
            setShowLoader(false);
            setErrorTitle(errorMsg.internalServer.errorTitle);
            setErrorDescription(errorMsg.internalServer.errorDesc);
            setError(true);
            window.scrollTo(0, 0);
          } else {
            setShowLoader(false);
            if (
              error.response.data.error.title ===
              errorResponse.accountAlreadyActiveError
            ) {
              navigate(href.accountActive);
            } else if (
              error.response.data.error.title ===
              errorResponse.problemProcessingRequest
            ) {
              navigate(href.requestError);
            } else if (
              error.response.data.error.title === errorMsg.errorResendActivationMail.errorTitle
            ) {
              setErrorTitle(error.response.data.error.title);
              setErrorDescription(errorMsg.errorResendActivationMail.errorDesc);
              setError(true);
              window.scrollTo(0, 0);
            } else {
              setErrorTitle(error.response.data.error.title);
              setErrorDescription(error.response.data.error.message);
              setError(true);
              window.scrollTo(0, 0);
            }
          }
        });
    }
  }

  const handleChange = () => {
    setError(false);
    setEmailError(false);
    setSsnError(false);
  };

  const handleResendActivation = () => {
    navigate(href.resendActivation);
  };

  const handleSignIn = () => {
    window.location.href = signInUrl;
  };

  return (
    <div className="bg">
      <div className="progress-stepper-style validate-progress">
        <ProgressIndicator
          className="web-progress-stepper"
          currentIndex={stepNumber}
          stepNames={stepNames}
        />
        <ProgressIndicator
          className="mobile-progress-stepper"
          currentIndex={stepNumber}
          stepNames={mobileStepNames}
        />
      </div>
      {showLoader ? (
        <LoaderComponent />
      ) : (
        <div className="div-container">
          <div className="form-element-resend-activation">
            <form onSubmit={(e) => handleSubmit(e)} className="fp-form">
              <div className="title title-mobile">
                <Title tag="h2" className="web-title">
                  {formTitle.resendActivationMail}
                </Title>
                <Title tag="h2" className="web-title">
                  {" "}
                  {formTitle.resendActivationMail2}
                </Title>

                <Title tag="h2" className="mobile-title" align="center">
                  {formTitle.resendActivationMail}
                </Title>
                <Title tag="h2" className="mobile-title" align="center">
                  {" "}
                  {formTitle.resendActivationMail2}
                </Title>
              </div>

              <div className="feedback-alert">
                <Alert
                  alertType="error"
                  title={errorTitle}
                  description={errorDescription}
                  showAlert={error || emailError || ssnError}
                />
              </div>

              <div className="description">
                <p className="headline-style">
                  {headline.resendActivationMail}
                </p>
                <p className="desc2">
                  {globalPinFlag
                    ? msg.resendActivationMail
                    : withoutGlobalPinMsg.resendActivationMail}
                </p>
              </div>

              <div className="box-with-link">
                <Label error={error || emailError}>{label.email}</Label>
                <InputEmail
                  id="email"
                  value={email}
                  name="email-input"
                  setValue={setEmail}
                  error={error || emailError}
                  noErrorsOrHelper
                  hideAlertIcon
                  autoFocus
                  onChange={handleChange}
                  data-testid="test-email"
                />
                <Button
                  variant="link"
                  size="small"
                  style={{ padding: "0", margin: "0" }}
                  onClick={handleResendActivation}
                  data-testid="test-btnResendActivation"
                >
                  {linkText.noEmailAccess}
                </Button>
              </div>

              {globalPinFlag ? (
                <div className="tab-gap-resend">
                  <Tabs>
                    <div label={label.governmentIdentifier}>
                      <InputSocialSecurity
                        id="governmentIdentifier"
                        value={governmentIdentifier}
                        name="governmentIdentifier-input"
                        setValue={setGovernmentIdentifier}
                        error={error || ssnError}
                        noErrorsOrHelper
                        hideAlertIcon
                        description={extraMsg.exampleSSN}
                        onChange={handleChange}
                        data-testid="test-governmentIdentifier"
                      />
                    </div>

                    <div label={label.globalPin}>
                      <InputOTP
                        id="globalPin"
                        value={globalPin}
                        name="globalPin-input"
                        setValue={setGlobalPin}
                        error={error}
                        description={extraMsg.exampleGlobalPin}
                        noErrorsOrHelper
                        hideAlertIcon
                        onChange={handleChange}
                      />
                    </div>
                  </Tabs>
                </div>
              ) : (
                <>
                  <div className="governmentIdentifier-input">
                    <Label error={error || ssnError}>
                      {label.governmentIdentifier}
                    </Label>
                    <InputSocialSecurity
                      id="governmentIdentifier"
                      value={governmentIdentifier}
                      name="governmentIdentifier-input"
                      setValue={setGovernmentIdentifier}
                      error={error || ssnError}
                      noErrorsOrHelper
                      hideAlertIcon
                      description={extraMsg.exampleSSN}
                      onChange={handleChange}
                      data-testid="test-governmentIdentifier"
                    />
                  </div>
                </>
              )}

              <Button
                type="submit"
                onClick={handleSubmit}
                size="medium"
                className="button-style"
                data-testid="test-btnResendActivationMail"
                style={{
                  width: "100%",
                  marginTop: "32px",
                  marginBottom: "0px",
                  padding: "12px 32px",
                }}
              >
                {button.resendActivationMail}
              </Button>
            </form>
          </div>
          <div className="resend-activation-image"></div>
        </div>
      )}
      <div className="centre-align out-of-box">
        <p>
          {extraMsg.existingCustomer}
          <Button
            variant="link"
            size="small"
            onClick={handleSignIn}
            style={{ padding: "0" }}
          >
            {linkText.signIn}
          </Button>
        </p>
      </div>
    </div>
  );
};

export default ResendActivationMail;
