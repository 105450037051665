import React from "react";
import { Footer } from "@creditonebank/c1b-components";
import mobileIcon from "../../assets/MobileIcon.svg";
import "./footer.styles.css";

export const FooterComponent = () => {
  const utilityLinks = [
    {
      icon: "pdf",
      label: "Privacy",
      link: "https://www.creditonebank.com/privacy",
    },
    {
      label: "Terms of Use",
      link: "https://www.creditonebank.com/terms-of-use",
    },
    {
      label: "Security & Fraud",
      link: "https://www.creditonebank.com/security",
    },
    {
      icon: "pdf",
      label: "Card Agreements",
      link: "https://www.creditonebank.com/content/dam/creditonebank/corporate-transactional/pdf/cardholderagreements.pdf",
    },
  ];
  return (
    <Footer
      utilityLinks={utilityLinks}
      copyright="© 2023 Credit One Bank, N.A. All Rights Reserved. Member FDIC."
      className="footer-style"
      utilityButton={{
        icon: {
          src: mobileIcon,
          alt: "Credit One Bank Mobile App Icon",
        },
        label: "Credit One Bank Mobile App",
        link: "https://www.creditonebank.com/mobile",
      }}
    />
  );
};
