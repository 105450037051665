import SetUpAccess from "../pages/account-set-up/set-up-access-deposit-account-link";


export const errorMsg = {
  enrollErrorTitle: "No verification method selected",
  enrollErrorDescription:
    "To enable two-step authentication, please turn on at least one verification method.",
  tokenExpired: "Your activation link has expired",
  missingUsername: "Please enter a username.",
  missingSecurityQuestionsAnswers: {
    errorTitle: "We’re missing required information",
    errorDesc:
      "Please select and enter an answer for all three security questions below.",
  },
  missingGovtId: {
    errorTitle: "We’re missing required information",
    errorDesc: "Please enter your Social Security number.",
  },
  missingUsernameAndGovtId: {
    errorTitle: "We’re missing required information",
    errorDesc: "Please enter your username and Social Security number.",
  },
  missingUsernameValue: {
    errorTitle: "We’re missing required information",
    errorDesc: "Please enter your username.",
  },
  missingEmailValue: {
    errorTitle: "We’re missing required information",
    errorDesc: "Please enter your email address.",
  },
  missingPhoneValue: {
    errorTitle: "We’re missing required information",
    errorDesc: "Please enter your phone number.",
  },
  missingSecurityAnswer: {
    errorTitle: "We're missing required information",
    errorDesc: "Please answer the security question.",
  },
  missingEmailAndGovtId: {
    errorTitle: "We're missing required information",
    errorDesc: "Please enter your email address and Social Security number.",
  },
  missingPhoneAndGovtId: {
    errorTitle: "We're missing required information",
    errorDesc: "Please enter your phone number and Social Security number.",
  },
  missingOtpCode: {
    errorTitle: "We're missing required information",
    errorDesc: "Please enter your verification code.",
  },
  internalServer: {
    errorTitle: "Internal server error",
    errorDesc:
      "There is a problem with the resource you are looking for and it cannot be displayed.",
  },
  errorResendActivation: {
    errorTitle: "We couldn’t verify your identity",
    errorDesc: "Check your phone number and Social Security number and try again.",
  },
  errorResendActivationMail: {
    errorTitle: "We couldn’t verify your identity",
    errorDesc: "Check your email address and Social Security number and try again.",
  },
  errorValidationActivation: {
    errorTitle: "We couldn’t verify your identity",
    errorDesc: "We don't recognize that Social Security number. Please try again.",
  },
  errorForgotUsernameDescFunc: function () {
    return <div className="error-description-text"><p>Check your email address and Social Security number and try again.</p>{this.depositLinkFunction()}</div>
  },
  errorMissingGovtIdDescFunc: function () {
    return <div className="error-description-text"><p>Please enter your Social Security number.</p>{this.depositLinkFunction()}</div>
  },
  errorGovtIdNotFoundDescFunc: function () {
    return <div className="error-description-text"><p>We don't recognize that Social Security number. Please try again.</p>{this.depositLinkFunction()}</div>
  },
  errorForgotPasswordDescFunc: function () {
    return <div className="error-description-text"><p>Check your username and Social Security number and try again.</p>{this.depositLinkFunction()}</div>
  },
  depositLinkFunction: function () {
    return <SetUpAccess/>
  },
};
