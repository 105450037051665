import React, { useEffect, useState } from 'react'

const SetUpAccess = () => {
    const [accessLink, setUpAccessLink] = useState();
    useEffect(async () => {
        await fetch("/env")
        .then((response) => response.json())
        .then((data) => {
            setUpAccessLink(data.REACT_APP_SET_UP_ACCESS_URL);
        }).catch((error) => {
            console.log(error);
        })
    }, []);
  return (
    <p className="deposit-account-link">If you already have a deposit account, <a href={accessLink}>set up access</a> to our new, improved platform.</p>
  )
}

export default SetUpAccess