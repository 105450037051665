export const href = {
  //Dashboard
  dashboard: "/access/home",
  
  //Forgot Username
  forgotUsername: "/forgot-username",
  forgotUsername2: "/forgot-username2",
  securityQuestion: "/security-question",
  forgotUsernameSuccess: "/forgot-username-success",

  //Forgot Password
  forgotPassword: "/forgot-password",
  forgotPasswordSecurity: "/security",
  resetPassword: "/reset-password",
  resetPasswordSuccess: "/reset-password-success",

  //Resend Activation Mail (Online Account Set Up - Link)
  resendActivationMail: "/resend-activation-email",
  resendActivation: "/resend-activation",
  updateEmail: "/update-email",
  resendActivationSuccess: "/resend-activation-success",

  //Account Set Up (Online Account Set Up - Mail)
  userValidate: "/uservalidate",
  validateActivation: "/validate-activation",
  activateUser: "/activate-user",
  tokenExpired: "/link-expired",
  enrollSecurityQuestions: "/enroll-security-questions",
  accountActivationSuccess: "/account-activated",

  //Reset MFA
  resetMfaInfo: "/reset-two-step-authentication",
  resetMfa: "/reset-mfa",
  validateSecurityQuestion: "/validate-security-question",
  confirmResetMfa: "/reset-factors",
  resetMfaSuccess: "/reset-factor-success",

  //Unlock Account
  unlockAccount: "/unlock-account",
  unlockAccountSuccess: "/unlock-account-success",

  //Error
  sessionExpired: "/session-expired",
  requestError: "/request-error",
  accountUnlockedError: "/account-unlocked",
  accountNotActive: "/account-error",
  accountActive: "/account-active",
  resetMfaError: "/reset-mfa-error",
  accountLocked: "/account-locked",

  //MFA Challenge
  mfaChallenge: "/mfa-challenge",
  mfaVerify: "/mfa-verify",

  //Page No Found Error Redirect
  error404: "https://www.creditonebank.com/404",
  //Internal Server Error
  error500: "https://www.creditonebank.com/500",
};
