import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { extraMsg, msg } from "../../text/form-message.text";
import { api } from "../../utils/api";
import { title } from "../../text/page-title.text";
import { button } from "../../text/button.text";
import { href } from "../../utils/ui-urls";
import { formTitle } from "../../text/form-title.text";
import { headline } from "../../text/form-headline.text.js";
import {
  InputSocialSecurity,
  Button,
  Title,
  Label,
} from "@creditonebank/c1b-components";
import { label } from "../../text/labels.text";
import Alert from "../../components/alert/alert.component";
import { LoaderComponent } from "../../components/loader/loader.component";
import { errorMsg } from "../../text/error-message.text";
import { errorResponse } from "../../text/error.responses.text";
import { axiosInstance } from "../../service/https.service";
import { checkServerError } from "../../functions/check-server-error.function";
import { BackToSignIn } from "../../components/link/back-to-signin.component";
import { HelpLink } from "../../components/link/help-link.component";

const ForgotUsername2 = ({ baseAPI, signInUrl, helpLink }) => {
  const [error, setError] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [governmentIdentifier, setGovernmentIdentifier] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = title.forgotUsername2;
    document.getElementById("governmentIdentifier__input__adv__helper")?.setAttribute("x-apple-data-detectors", false);
    window.scrollTo(0, 0);
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    setErrorTitle("");
    setErrorDescription("");
    setError(false);

    if (governmentIdentifier === "") {
      setErrorTitle(errorMsg.missingGovtId.errorTitle);
      setErrorDescription(errorMsg.errorMissingGovtIdDescFunc());
      setError(true);
      window.scrollTo(0, 0);
    } else {
      setShowLoader(true);
      axiosInstance
        .post(
          baseAPI + api.forgotUsername,
          {
            governmentIdentifier: governmentIdentifier,
          },
          {
            headers: {
              accessEmail: false,
            },
          }
        )
        .then((response) => {
          setShowLoader(false);
          setError(false);
          navigate(href.securityQuestion, {
            state: {
              id: response.data.id,
            },
          });
        })
        .catch((error) => {
          if (checkServerError(error.response.status)) {
            setShowLoader(false);
            setErrorTitle(errorMsg.internalServer.errorTitle);
            setErrorDescription(errorMsg.internalServer.errorDesc);
            setError(true);
            window.scrollTo(0, 0);
          } else {
            setShowLoader(false);
            if (
              error.response.data.error.title === errorResponse.accountNotActive
            ) {
              navigate(href.accountNotActive);
            } else if (
              error.response.data.error.title ===
              errorResponse.problemProcessingRequest ||
              error.response.data.error.title === errorResponse.accountSuspended
            ) {
              navigate(href.requestError);
            } else {
              setErrorTitle(error.response.data.error.title);
              setErrorDescription(errorMsg.errorGovtIdNotFoundDescFunc());
              setError(true);
              window.scrollTo(0, 0);
            }
          }
        });
    }
  }

  const handleChange = () => {
    setError(false);
  };

  return (
    <div className="bg">
      {showLoader ? (
        <LoaderComponent />
      ) : (
        <div className="form-element-short">
          <form onSubmit={(e) => handleSubmit(e)} className="form">
            <div className="title">
              <Title tag="h2" className="web-title">
                {formTitle.forgotUsername2}
              </Title>

              <Title tag="h2" className="mobile-title" align="center">
                {formTitle.forgotUsername2}
              </Title>
            </div>

            <div className="feedback-alert">
              <Alert
                alertType="error"
                title={errorTitle}
                description={errorDescription}
                showAlert={error}
              />
            </div>

            <div className="description">
              <p className="headline-style">{headline.forgotUsername2}</p>
              <p className="desc2">{msg.forgotUsername2}</p>
            </div>

            <Label error={error}>{label.governmentIdentifier}</Label>
            <InputSocialSecurity
              id="governmentIdentifier"
              value={governmentIdentifier}
              name="governmentIdentifier-input"
              setValue={setGovernmentIdentifier}
              error={error}
              noErrorsOrHelper
              hideAlertIcon
              autoFocus
              description={extraMsg.exampleSSN}
              onChange={handleChange}
              data-testid="test-governmentIdentifier"
            />

            <div>
              <Button
                type="submit"
                onClick={handleSubmit}
                size="medium"
                className="button-style-success"
              >
                {button.forgotUsername2}
              </Button>
            </div>
            <div className="centre-align inside-box">
              <BackToSignIn signInUrl={signInUrl} />
            </div>
          </form>
        </div>
      )}

      <div className="centre-align out-of-box">
        <HelpLink helpLink={helpLink} />
      </div>
    </div>
  );
};

export default ForgotUsername2;
